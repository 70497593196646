// Gatsby requirements
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

// Storyblok preview
import { storyblokEditable, useStoryblokState } from "gatsby-source-storyblok"

// Components
import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import RichTextRenderer from '../components/richTextRenderer'
import Breadcrumbs from '../components/breadcrumbs'
import Image from '../components/image'

// Storyblok Components
import StoryblokComponents from 'utils/StoryblokComponents'

// Styles
import * as CSS from './article.module.scss'


const ArticleTemplate = ({ data }) => {

    const [previewStory, setPreviewStory] = useState(null);

    let story = useStoryblokState(data.storyblokEntry)

    useEffect(() => {
        if (window.location.search.includes('_storyblok')) {
            setPreviewStory(null);
        }
    }, [story]);

    useEffect(() => {
        if (window.location.search.includes('_storyblok')) {
            const url = `https://api.storyblok.com/v2/cdn/stories/${data.storyblokEntry.full_slug}?version=draft&token=${process.env.GATSBY_STORYBLOK_ACCESS_TOKEN}&cv=${Date.now()}`;
        
            const fetchData = async () => {
                try {
                    const response = await fetch(url);
                    const json = await response.json();
                    setPreviewStory(json.story);
                } catch (error) {
                    console.log("error", error);
                }
            };
        
            fetchData();
        }
    }, []);

    // Gives nofollow to links that have been given nofollow class in storyblok
    useEffect(() => {
        var els = document.querySelectorAll('.nofollow');

        for (var i = 0; i < els.length; i++) {
            els[i].classList.remove('nofollow');
            els[i].parentNode.setAttribute("rel", "nofollow");
        };
    },[]);

    
    return (
        <Layout
            en={data.storyblokEntry?.full_slug?.startsWith('en/')}
            alternateSlug={`/${data.storyblokEntry?.content?.translation_of?.cached_url}`}
        >

            <div {...storyblokEditable(story.content)}>

                <Seo
                    title={data.storyblokEntry?.field_meta_title_string}
                    description={data.storyblokEntry?.field_meta_description_string}
                    thumbnail={data.storyblokEntry.content?.meta_image?.filename}
                    type="Article"
                    url={`/${data.storyblokEntry?.full_slug}`}
                    noindex={data.storyblokEntry?.content?.noindex}
                    en={data.storyblokEntry?.full_slug?.startsWith('en/')}
                    alternateSlug={`/${data.storyblokEntry?.content?.translation_of?.cached_url}`}
                />

                {story.content?.hideBreadcrumbs === true ? '' : 
                    <Breadcrumbs className={CSS.breadcrumbs} currentPageName={data.storyblokEntry?.name} currentPage={`/${data.storyblokEntry?.full_slug}`} />
                }

                {story.content?.fullWidthHeaderImage?.filename &&
                    <div className={CSS.fullWidthHeaderImage}>
                        <Image
                            img={story.content?.fullWidthHeaderImage}
                            sizes="(min-width: 1200px) 1400px, 125vw"
                            loading="eager"
                            fullwidth={true}
                        />
                    </div>
                }

                <div className={`${CSS.article} wrapper`}>
                    <main className={CSS.content}>
                        <article id="content">
                            {story.content.hidePublishDateAndTags !== true &&
                                <>
                                    {story.tag_list.length > 0 && <a href={'/' + story.tag_list[0].toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')} className={CSS.tag}>{story.tag_list[0]}</a>}
                                    {story.published_at && <span className={CSS.publishedAt}>{story.published_at}</span>}
                                </>
                            }
                            <RichTextRenderer data={previewStory ? previewStory.content.article : story.content.article} currentPage={`/${data.storyblokEntry?.full_slug}`} />
                        </article>
                    </main>

                    {story.content?.sidebar_blocks?.length > 0 &&
                        <aside aria-label="Sidebar" className={`sidebar ${CSS.sidebar}`}>
                            <StoryblokComponents
                                content={previewStory ? previewStory?.content?.sidebar_blocks : story.content?.sidebar_blocks}
                                en={data.storyblokEntry?.full_slug?.startsWith('en/')}
                                currentPageName={data.storyblokEntry?.name}
                                currentPage={data.storyblokEntry?.full_slug === 'home' ? '/' : `/${data.storyblokEntry?.full_slug}`}
                            />
                        </aside>
                    }
                </div>

                <aside className={CSS.blocksAfterContent} aria-label="Related content under article">
                    <StoryblokComponents
                        content={previewStory ? previewStory?.content?.blocks_after_article : story.content?.blocks_after_article}
                        en={data.storyblokEntry?.full_slug?.startsWith('en/')}
                        currentPageName={data.storyblokEntry?.name}
                        currentPage={data.storyblokEntry?.full_slug === 'home' ? '/' : `/${data.storyblokEntry?.full_slug}`}
                    />
                </aside>

            </div>

        </Layout>
    )
}

// Page query, the blocks are in "content" as JSON
export const query = graphql`
  query ($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
        full_slug
        field_meta_description_string
        field_meta_title_string
        field_noindex_boolean
        name
        content
        tag_list
        published_at(formatString: "DD.M.YYYY")
        id
        uuid
        internalId
    }
  }
`

export default ArticleTemplate
